import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Chart from '../../components/chart';
import Hero from './Hero';
import Table from './Table';
import About from './About';
import Features from './Features';
import Footer from './Footer';
import Demo from './Demo';
import Signup from '../../components/Signup';
import SpendingBreakdown from './SpendingBreakdown';
import { spendingMap, hasValidSpendingValues } from '../../utils/spending';
import News from './News';
import EcoChartVisx from '../../components/EcoChart';
import { ParentSize } from '@visx/responsive';
import { useMediaQuery } from 'react-responsive';
import { Download } from './Download';

const Pilot = ({
  pageContext: {
    pilotName,
    pilotLocation,
    pilotShortName,
    participants,
    amount,
    paymentFrequency,
    duration,
    invested,
    durationDates,
    eligibility,
    medianHouseholdIncome,
    pilotDescription,
    logo,
    logoLink,
    mapLink,
    demographicPDF,
    pilotCity,
    pilotSummary,
    spending: initialSpending,
    sampleStory,
    prepaid,
    slug,
    lastUpdated,
    aboutHeading,
    id,
    demographicsData,
    unemploymentData = [],
  },
}) => {
  const spending = Object.entries(initialSpending)
    .map(([key, value]) => ({ label: spendingMap[key], value }))
    .sort((a, b) => b.value - a.value);

  const cityUnemploymentData = [];
  const city1Data = [];
  const city2Data = [];

  unemploymentData.forEach((obj) => {
    if (
      obj.pilot_id === id ||
      obj.pilot_id2 === id ||
      obj.pilot_id3 === id ||
      obj.pilot_id4 === id
    ) {
      if (obj.area === 'New Orleans-Metairie, LA Metropolitan Statistical Area') {
        city1Data.push(obj.unemployment_rate);
      } else if (obj.area === 'Indianapolis-Carmel-Anderson, IN Metropolitan Statistical Area') {
        city2Data.push(obj.unemployment_rate);
      } else {
        cityUnemploymentData.push(obj.unemployment_rate);
      }
    }
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  return (
    <Layout>
      <SEO
        title={pilotName}
        description={`Learn about the guaranteed income pilot in ${pilotCity}.`}
        image={`https://guaranteedincome.us/images/${slug}-hero.jpg`}
      />
      <main className="relative overflow-hidden flex justify-center">
        <Hero
          slug={slug}
          pilotCity={pilotCity}
          pilotName={pilotName}
          pilotSummary={pilotSummary}
          participants={participants}
          amount={amount}
          duration={duration}
          invested={invested}
        />
      </main>
      <div className={`bg-tan ${pilotCity?.replaceAll(' ', '-').toLowerCase()}-about`}>
        <div className="container mx-auto ">
          <div className="ml-3 md:ml-5 text-center md:text-left lg:text-left xl:text-left  mt-5 2xl:text-left">
            <a href={logoLink} target="_blank" rel="noreferrer noopener">
              <img className="max-h-[136px] max-w-[320px]" src={logo} alt="logo" />
            </a>
          </div>
          <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap">
            <div className="md:flex-1 lg:flex-1 xl:flex-1 2xl:flex-1 ">
              <section className="flex-grow flex flex-col">
                <Table
                  participants={participants}
                  amount={amount}
                  paymentFrequency={paymentFrequency}
                  durationDates={durationDates}
                  eligibility={eligibility}
                  medianHouseholdIncome={medianHouseholdIncome}
                />
              </section>
            </div>
            <div className="md:flex-1 lg:flex-1 xl:flex-1 2xl:flex-1 flex-col mx-auto">
              <div
                className={`bg-usa-climb h-[320px] md:h-96 flex-1 w-full md:h-full lg:h-full xl:h-full 2xl:h-full flex flex-col justify-end`}
                style={{
                  backgroundImage: `url(/images/${slug}-map.svg)`,
                }}
              >
                <div className="flex justify-center mb-5">
                  <a
                    href={mapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="map-button button border bg-bronze cta2 text-white cursor-pointer w-full md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/3 text-center "
                  >
                    View on Global map of experiments
                  </a>
                </div>
              </div>
            </div>
          </div>
          <section>
            <About
              pilotName={pilotName}
              pilotCity={pilotCity}
              pilotDescription={pilotDescription}
              demographicPDF={demographicPDF}
              lastUpdated={lastUpdated}
              aboutHeading={aboutHeading}
            />
          </section>
          <section>
            <Features />
          </section>
        </div>
      </div>
      <Download pilotLocation={pilotLocation} pilotCity={pilotCity} slug={slug} />
      {pilotCity !== 'St Paul PPP' &&
        pilotCity !== 'Paterson' &&
        pilotCity !== 'Cambridge' &&
        pilotCity !== 'Richmond' &&
        pilotCity !== 'Columbia' &&
        pilotCity !== 'New Orleans' &&
        pilotCity !== 'Los Angeles' &&
        pilotCity !== 'Shreveport' &&
        pilotCity !== 'Birmingham' &&
        pilotCity !== 'Providence' &&
        pilotCity !== 'Tacoma' &&
        demographicsData && (
          <section class="alt-bg">
            <Demo
              demographicsData={demographicsData}
              demographicPDF={demographicPDF}
              pilotCity={pilotCity}
              pilotName={pilotName}
              pilotShortName={pilotShortName}
              pilotLocation={pilotLocation}
            />
          </section>
        )}

      <section className="alt-bg flex flex-col place-items-center px-2 pt-7 md:pt-8 lg:pt-9 pb-3 md:pb-0 lg:pb-3">
        <div className="container mx-auto px-1 md:px-3 lg:px-5">
          <h2 className={'mb-6 '}>Economic Context for Pilot Participants</h2>
          <p className="lg:max-w-[66%]">
            The guaranteed income pilot launched during a period of dynamic change and economic
            challenges for pilot participants. The chart below situates this pilot in the context of
            unemployment and inflation.
          </p>
        </div>
        <div className="container mx-auto md:px-5">
          {isMobile ? (
            <div className="mb-4">
              <EcoChartVisx
                durationDates={durationDates}
                pilots={[]}
                margin={{
                  top: 0,
                  right: 0,
                  bottom: 100,
                  left: 70,
                }}
                pilotCity={pilotCity}
                width={1000}
                height={500}
                isHomepage={false}
                city2Data={city2Data}
                isDoublePilot={id === '9d3300b2-6555-446e-b5c6-efbf86808e8f'}
                unemploymentData={
                  id === '9d3300b2-6555-446e-b5c6-efbf86808e8f' ||
                  id === '318ebd47-a913-4685-838d-16dcc261d04a'
                    ? city1Data
                    : cityUnemploymentData
                }
              />
            </div>
          ) : (
            <div
              style={{ height: '500px' }}
              className={'mb-[140px] h-full w-full ml-1 pr-2 md:ml-4 md:pr-4'}
            >
              <ParentSize>
                {({ width, height }) => (
                  <EcoChartVisx
                    durationDates={durationDates}
                    margin={{
                      top: 0,
                      right: 30,
                      bottom: 100,
                      left: 40,
                    }}
                    pilots={[]}
                    pilotCity={pilotCity}
                    height={height}
                    width={width}
                    isHomepage={false}
                    city2Data={city2Data}
                    isDoublePilot={id === '9d3300b2-6555-446e-b5c6-efbf86808e8f'}
                    unemploymentData={
                      id === '9d3300b2-6555-446e-b5c6-efbf86808e8f' ||
                      id === '318ebd47-a913-4685-838d-16dcc261d04a'
                        ? city1Data
                        : cityUnemploymentData
                    }
                  />
                )}
              </ParentSize>
            </div>
          )}
          ;
        </div>
      </section>

      {hasValidSpendingValues(spending) && (
        <section className="alt-bg">
          <div
            className={`${slug}-spending container mx-auto px-3 md:px-5 pt-6 md:pt-8 lg:pt-[72px] pb-6 md:pb-8 lg:pb-[104px]`}
          >
            <h2 className="mb-6">Spending Breakdown</h2>
            <div className="container w-full flex-1">
              <div
                className="flex flex-col xl:flex-row-reverse xl:items-center xl:justify-end
              "
              >
                <div className="h-[540px] w-full lg:w-3/4 lg:h-[400px] xl:w-[800px] xl:h-[360px] pr-2 mb-5 xl:mb-0">
                  <Chart spending={spending} />
                </div>
                <div className="lg:max-w-full xl:max-w-[29%]">
                  <p className="mb-2">
                    {`The data in this pie chart shows aggregate spending for guaranteed income participants in ${pilotLocation}.
                  This includes all non-cash expenditures from the participants'
                  ${
                    prepaid
                      ? `pre-paid debit card that is loaded with guaranteed income payments ${paymentFrequency?.toLowerCase()}.`
                      : `bank accounts, not just those that are from the guaranteed income they received.`
                  }`}
                  </p>
                  <SpendingBreakdown spending={spending} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section
        className={`bg-forest ${pilotCity?.replaceAll(' ', '-').toLowerCase()}-stories-teaser`}
      >
        <Footer {...sampleStory} />
      </section>
      <section>
        <News pilotID={id} />
      </section>
      <Signup />
    </Layout>
  );
};

export default Pilot;
